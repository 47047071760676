import { Component } from '@angular/core';
import { data } from '../../../app.data';

@Component({
  templateUrl: './ang-validators.component.html'
})
export class AngValidatorsComponent {
  data = data.angValidatorsData.data;

  basicValidator =
`import { FormControl, FormGroup, Validators } from '@angular/forms';
import { customEmailValidator } from '../validators/email-validator';
...
//Validating a specific field
this.emailForm = new FormGroup({
    email:  new FormControl(['', [Validators.required, customEmailValidator()]])
});
  
//Or... validating the entire form group, rather than single field
this.emailForm = new FormGroup({
    email:  new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
},
// Notice the arrow function on this line
// This is how you can make sure you have 
// access to 'this' in your validator
{ validators: cv => this.customEmailValidator(cv)
});`;
}

<footer class="app-footer">
    <div class="footer-wrapper">
        <section class="footer-inner-wrapper">
            <div class="footer-left">
                <p class="mt-0 mb-10 text-color-darkblue" style="font-size:30px;font-weight:600;">Jemigo</p>
                <p class="mt-0 mb-10 text-color-lightblue font-secondary" style="font-size:17px;text-transform: uppercase;font-weight:500;letter-spacing: 2.5px;">The WFH Hub</p>
                <p class="mt-0 mb-0 text-color-darkblue" style="font-size:18px;">🏠 not 🏢</p>
            </div>
            <div class="footer-center">
                <a class="footer-link" routerLink="/about">About Us</a>
                <a class="footer-link" target="_blank"
                href="https://www.freeprivacypolicy.com/live/4de82284-c5f7-4eaf-9e6b-0392af324306">Terms</a>
                <a class="footer-link" target="_blank"
                href="https://www.freeprivacypolicy.com/live/f1816af5-2069-4225-a8c1-6fc6d45e8816">Privacy Policy</a>
            </div>
            <div class="footer-right">
                <img decoding="async" alt="Jemigo logo" src="/assets/icons/jmg-72.png" class="footer-image">
                <p class="mt-0 mb-0 font-secondary" style="margin-top:-10px;margin-bottom:5px;color: #54595F;font-size:17px;text-transform: uppercase;font-weight:500;letter-spacing: 2.5px;">Follow Us</p>
                <div>
                    <a style="padding-right:5px;" target="_blank" href="https://www.reddit.com/user/jemigo_/">
                        <img style="width:28px;" src="/assets/images/reddit.png" alt="Reddit logo">
                    </a>
                    <a style="padding-left:5px;padding-right:5px;" href="https://www.pinterest.com/jemigo_" target="_blank">
                        <img style="width:28px;" alt="Pinterest logo" src="/assets/images/pinterest.png">
                    </a>
                    <a style="padding-left:5px;padding-right:5px;" href="https://www.tiktok.com/@jemigo.com" target="_blank">
                        <img style="width:28px;" alt="Tiktok logo" src="/assets/images/tiktok.png">
                    </a>
                    <a style="padding-left:5px;" href="https://www.youtube.com/@jemigo" target="_blank">
                        <img style="width:28px;" alt="YouTube logo" src="/assets/images/youtube.png">
                    </a>
                </div>
            </div>
        </section>
    </div>
    <section class="m-0 py-15">
        <div class="mb-10" style="font-size: 14px;font-weight:600;">&copy; {{year}} Jemigo LLC</div>
        <div style="font-size: 14px;font-weight:600;">Design by <a href="https://crispwebservices.com/" target="_blank">Crisp Web Services</a></div>
    </section>
</footer>
<div class="article-background">
    <main class="center-not-text article-wrapper">
        <article-header [data]="data"></article-header>
        <article-disclosure></article-disclosure>
        <article>
            <ng-content></ng-content>
        </article>
        <scroll-top-button></scroll-top-button>
    </main>
</div>

<main>
    <header class="center" style="max-width:1400px;">
        <a routerLink="/"><img src="/assets/icons/jmg-192.png" alt="Jemigo logo"></a>
        <h1>{{title}}</h1>
    </header>
    <section class="center-not-text" style="max-width:1400px;">
        <p class="about-text">Jemigo is a work from home hub that provides
            <a href="/blog">tips, guides,</a> and the best WFH products to enhance your remote work lifestyle.
        </p>
        <p class="about-text">
            <strong>We strongly believe that working from home leads to greater personal happiness, 
        a more natural society, and can help save the environment.</strong>
        </p>
        <p class="about-text">🏠❤️ Home is where our heart is.</p>
        <p class="about-text">At Jemigo we're advocates of inclusion, diversity, nature, science, and humanity. We believe in genuineness and that all people deserve respect and understanding.
        </p>
        <p class="about-text">We also love trees&nbsp;&nbsp;
            <a target="_blank" href="https://www.arborday.org/trees/treefacts/">
                <span style="letter-spacing:-.9rem;display:inline-block;max-width: 3.3rem;"> &nbsp;🌲🌳🌲</span>
            </a>
            &nbsp; ⬅️ Click the trees if you don't know why they're awesome.
        </p>
        <p class="about-text">
            Please consider joining us in helping the environment, and consequently your own future.
            <a target="_blank" href="https://forest-fundraiser.raisely.com/jemigo/">Click here</a> to donate (each dollar plants one tree).
        </p>
        <p class="about-text">
            1💲 = 1 🌱
        </p>
        <br>
        <h2 class="center my-40">Contact</h2>
        <p class="center mb-70">admin@jemigo.com</p>
        <br>
    </section>
</main>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'jmg-btn',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Output() onClick = new EventEmitter<any>();
  @Input() color = "orange";
  faLongArrowAltRight = faLongArrowAltRight;

  onClickButton(event) {
    this.onClick.emit(event);
  }
}
import { Component } from '@angular/core';
import { data } from '../../../app.data';

@Component({
  selector: 'ang-pipes-article',
  templateUrl: './ang-pipes.component.html'
})
export class AngPipesComponent{
  data = data.angPipesData.data;

  basicPipeCode = 
`<!-- Basic pipe to display value in lowercase -->
<p> {{ myValue | lowercase }} </p>

<!-- Date pipe with formatting argument -->
<p> {{ myDate | date:'M/d/yyyy' }} </p>

<!-- Pipe chaining example -->
<p> {{ myValue | lowercase | customPipe }} </p>

<!-- Avoid binding to functions -->
<p> {{ getFormat(myValue) }} </p>`;

  pipesInComponents =
`import { Component } from '@angular/core';
import { MyPipe } from 'src/app/shared/pipes/my-pipe.pipe';

@Component({
    selector: 'my-component',
    templateUrl: './my-component.component.html',
    providers: [MyPipe]
})
export class MyComponent {
    constructor(private myPipe: MyPipe) { }

    doSomething(): void {
        const transformedValue = this.myPipe.transform();
    }
}`;



}

import { Injectable } from "@angular/core";
import { DynamicArticleComponent } from "./dynamic/dynamic-templates/dynamic-article.component";
import { AppData, ArticleImage, RouteData } from "./app.data";
import { DynamicComponent } from "./dynamic/dynamic.component";
import { HttpClient } from "@angular/common/http";
import { map, take } from "rxjs";
import { environment } from "src/environments/environment";
import { BlogData } from "./shared/interfaces/blog-interface";

@Injectable({
  providedIn: "root",
})
export class BlogService {
  private rootUrl = environment.url;
  constructor(private http: HttpClient) {}

  getBlogRouteData() {
    return this.getBlogData().pipe(
      map((data) => this.blogDataToRouteData(data)),
    );
  }

  getBlogData() {
    return this.http.get<Array<BlogData>>(`${this.rootUrl}/api/blog/data`);
  }

  getBlogDataById(id: string) {
    return this.http.get<BlogData>(`${this.rootUrl}/api/blog/data/${id}`).pipe(
      take(1),
      map((data) => this.mapBlogData(data))
    );
  }

  saveBlogData(data: BlogData) {
    if(data._id){
      return this.updateBlogData(data);
    }
    const body = { blogData: data };
    return this.http.post<{message: string}>(`${this.rootUrl}/api/blog/data/save`, body);
  }

  updateBlogData(data: BlogData) {
    const body = { blogData: data };
    return this.http.post<{message: string}>(`${this.rootUrl}/api/blog/data/update`, body);
  }

  private blogDataToRouteData(data: Array<BlogData>): RouteData[] {
    return data?.map((blog) => this.mapBlogData(blog));
  }

  mapBlogData(blog: BlogData): RouteData {
    if(!blog) return undefined;
    const appData: AppData = {
      parent: "blog",
      title: blog.title,
      description: blog.description,
      image: new ArticleImage(blog.imageName, blog.imageFormat, blog.imageAlt),
      articleDate: blog.articleDate,
      modifiedDate: blog.modifiedDate,
      article: blog.article,
      tags: blog.tags,
      component: DynamicArticleComponent,
    };
    const rd = new RouteData(blog.path, blog.title, appData);
    rd.component = DynamicComponent;
    return rd;
  }
}

import { Component } from '@angular/core';
import { data } from 'src/app/app.data';

@Component({
  template: `
  <dev-nav></dev-nav>
  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{title}}</h1>
    <p>A validator that ensures a password includes at least one uppercase letter, one lowercase letter,
      and one number. This could be combined with a max length validator to enforce stronger passwords.</p>
    <pre>
      <code [highlight]="pwdValidator"></code>
    </pre>
  </div>
  `,
})
export class PwStrengthValidatorComponent {
  title = data.angPwStrengthValidatorData.title;

  pwdValidator =
`import {AbstractControl, ValidationErrors, 
  ValidatorFn} from '@angular/forms';

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const passwordValid = hasUpperCase && hasLowerCase 
      && hasNumeric;

    return !passwordValid ? 
      {passwordStrength:true}: null;
  }
}`;
}

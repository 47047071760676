import { Component, Input } from '@angular/core';
import { AppData } from '../app.data';

@Component({
  selector: 'article-header',
  templateUrl: './article-header.component.html'
})
export class ArticleHeaderComponent {
  @Input() data: AppData | undefined = undefined;
}

<dev-nav></dev-nav>

<div style="padding: 20px; max-width: 900px">
  <h1 class="m-0">{{ data.title }}</h1>
  <p class="updated-date">Last Updated: {{ data.modifiedDate | date }}</p>

  <p>
    Angular pipes are used to easily transform data into useful formats for
    display. The framework has several common ones built-in, but most of the
    time, projects will require a particular format, for which you will want to
    write a custom pipe.
  </p>
  <h2>Basics Pipe Examples</h2>
  <pre>
    <code [highlight]="basicPipeCode" [languages]="['xml']"></code>
  </pre>
  <p>
    You can pass values to pipes as arguments like in the above example. It
    passes the string <em>'M/d/yyyy'</em> as the <em>date</em> parameter to
    determine the formatting options.
  </p>
  <p>
    You can chain pipes, meaning you can use the output of one, for the input of
    another.
  </p>
  <h2>Pipes In Components</h2>
  <p>
    To use a pipe inside component code, inject it in the constructor and
    declare it as a provider like this basic example.
  </p>
  <pre>
    <code [highlight]="pipesInComponents"></code>
  </pre>
  <h2>Change Detection for Pipes</h2>
  <p>
    Pure pipes don't run in the same change detection cycle as the rest of the
    component. Pipes are used for more than just transforming values, they
    actually increase template rendering performance.
  </p>
  <p>
    When Angular runs a pipe transform on a value, it remembers the result and
    doesn't have to rerun the pipe when it's given the same value. When you have
    a large collection of values, this is essential. Pipes run on their own,
    faster
    <a
      target="_blank"
      href="https://angular.io/guide/pipes#how-change-detection-works"
    >
      change detection algorithm per the docs</a
    >.
  </p>
  <p>
    A common mistake in Angular is to bind a value in a template to a function
    in your component. When you do this instead of using a pipe, the function
    runs on every change detection cycle. You can easily end up with hundreds of
    unneeded function calls.
  </p>
  <p>
    Pipes are by default "pure" unless designated otherwise. The Async pipe is
    an example of an "impure" pipe. Pure means that any given input will always
    return the exact same output.
  </p>
  <p></p>
  <p><strong>Where do I put my Angular pipes in my project?</strong></p>
  <p>
    Put them in a shared folder and shared module if you want to share it with
    multiple libraries or apps. You can place it wherever you want really, but
    you only need to declare it once in the app or shared module if you want to
    share it across the application.
  </p>
  <p>
    You need to include the pipes in both the declarations and exports sections
    of your app or shared module.
  </p>
  <p>
    For more in depth information, visit the Angular documentation on
    <a target="_blank" href="https://angular.io/guide/pipes">pipes</a>.
  </p>
  <h2 class="mb-0">Built-in Pipes</h2>
  <section>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/AsyncPipe"
        >Async Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/CurrencyPipe"
        >Currency Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/DatePipe"
        >Date Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/DecimalPipe"
        >Decimal Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/I18nPluralPipe"
        >I18nPlural Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/I18nSelectPipe"
        >I18nSelect Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/JsonPipe"
        >Json Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/KeyValuePipe"
        >KeyValue Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/LowerCasePipe"
        >LowerCase Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/PercentPipe"
        >Percent Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/SlicePipe"
        >Slice Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/TitleCasePipe"
        >TitleCase Pipe</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/common/UpperCasePipe"
        >UpperCase Pipe</a
      >
    </p>
  </section>

  <h2 class="mb-0">Custom Pipes</h2>
  <section>
    <p class="my-0">
      <a routerLink="zip-code">Zip Code Pipe</a>
    </p>
    <p class="my-0">
      <a routerLink="month-number">Month Name Pipe</a>
    </p>
    <p class="my-0"><a routerLink="phone">Phone Pipe</a></p>
    <p class="my-0">
      <a routerLink="numeric-range">Numeric Range Pipe</a>
    </p>
  </section>
</div>

import { Component } from '@angular/core';
import { data } from '../../../app.data';

@Component({
  templateUrl: './templates.component.html'
})
export class AngTemplatesComponent {
  data = data.angTemplatesData.data;

  templateAliasCode =
`<!-- "As" syntax for aliasing -->
<div *ngIf="myObservable$ | async as myAlias">
  <div *ngIf="myAlias?.myProp === 'thing1'">
    ...
  </div>
  <div *ngIf="myAlias?.myProp === 'thing2'">
    ...
  </div>
  <div *ngIf="myAlias?.myProp === 'thing3'">
    ...
  </div>
</div>`;

  templateExample1 =
`<!-- ng-container does not generate any wrapper HTML. 
Used often when you don't want an outer element -->
<ng-container *ngIf="!isLoading; else loading">
<div *ngFor="let item of myArray">
{{ item }}
</div>
</ng-container>

<ng-template #loading>
<my-spinner></my-spinner>
</ng-template>`;
}

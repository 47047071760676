import { Component } from '@angular/core';
import { data } from 'src/app/app.data';

@Component({
  selector: 'zip-pipe-article',
  template: `
  <dev-nav></dev-nav>
  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{title}}</h1>
    <p>A pipe that enforces the input of numeric digits in a text field and automatically
      adds a dash (if needed) between the first five digits and the last four digits.</p>
    <pre>
      <code [highlight]="zipCodePipe"></code>
    </pre>
  </div>
  `,
})
export class ZipPipeComponent {
  title = data.zipCodePipeData.title;

  zipCodePipe =
`import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "zipCode" })

export class ZipCodePipe implements PipeTransform {
  transform(value: string | undefined) {
    if(!value){
      return "";
    }
    
    let formattedValue = value.replace(/\D/g,"");
    if(!formattedValue || formattedValue?.length < 5) {
      return formattedValue;
    }

    formattedValue = formattedValue.trim();
    const zipWithoutDash = formattedValue.replace(/-/g, "");
    
    if(!zipWithoutDash || zipWithoutDash.length < 5) {
      return formattedValue;
    }
    
    const firstFiveDigits = zipWithoutDash.slice(0,5);
    const lastFourDigits = 
          zipWithoutDash.length > 5 ? zipWithoutDash.slice(5) : "";
    const lastFourWithDash = 
          lastFourDigits ? "-" + lastFourDigits : "";
    
    return \`\${firstFiveDigits}\${lastFourWithDash}\`;
  }
}`;
}

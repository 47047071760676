import { Component, Input } from '@angular/core';
import { AppData } from '../app.data';

@Component({
  selector: 'jmg-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent {
    @Input() data: AppData | undefined = undefined;
}

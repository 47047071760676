import { NgModule } from '@angular/core';
import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { ArticleHeaderComponent } from '../article/article-header.component';
import { ArticleShareComponent } from '../article/article-share.component';
import { QuoteComponent } from '../article/quote.component';
import { ScrollTopButtonComponent } from '../article/scroll-top-button.component';
import { SliderComponent } from './components/slider/slider.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared.module';
import { ArticleComponent } from '../article/article.component';
import { AmzProductComponent } from '../article/amz-product.component';

@NgModule({
    declarations: [
        ArticleHeaderComponent,
        AmzProductComponent,
        ArticleShareComponent,
        ArticleComponent,
        QuoteComponent,
        ScrollTopButtonComponent,
        SliderComponent
    ],
    imports: [
        CommonModule, 
        FormsModule,
        RouterModule,
        FontAwesomeModule, 
        ShareButtonsModule, 
        ShareIconsModule,
        SharedModule,
        NgOptimizedImage,
    ],
    exports: [
        ArticleHeaderComponent,
        AmzProductComponent,
        ArticleShareComponent,
        ArticleComponent,
        QuoteComponent,
        ScrollTopButtonComponent,
        SliderComponent,
        CommonModule, 
        FormsModule,
        RouterModule,
        FontAwesomeModule, 
        ShareButtonsModule, 
        ShareIconsModule
    ],
    providers: [
        {
            provide: IMAGE_CONFIG,
            useValue: {
              breakpoints: [400, 640]
            }
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                if(config.width){
                    const src = config.src.split(".");
                    return `${src[0]}-${config.width}w.${src[1]}`;
                }
                else{
                    const src = config.src.split(".");
                    return `${src[0]}-400w.${src[1]}`;
                }
            },
        },
    ]
})
export class ArticlesModule { }

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouteData } from '../app.data';

@Component({
  selector: 'article-related',
  templateUrl: './article-related.component.html'
})
export class ArticleRelatedComponent {

  @Input() articlesData: RouteData[] = [];
  @Input() showHeader = true;
  @Input() centerLinks = false;
  @Input() smallerVersion = false;
  constructor(public router: Router) { }
}

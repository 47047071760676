import { Component } from '@angular/core';
import { AppData } from '../../app.data';
import { ArticleInterface } from '../../shared/interfaces/article-interface';

@Component({
  templateUrl: './dynamic-article.component.html',
})
export class DynamicArticleComponent implements ArticleInterface {
  data: AppData;
}


import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { data } from '../app.data';
import { AmzProduct } from '../shared/interfaces/blog-interface';
import { AppService } from '../app.service';
@Component({
  selector: 'reviews',
  templateUrl: './reviews.component.html'
})
export class ReviewsComponent {
  allReviews = [
    data.reviewEnergyDrinksData
  ]

  asins = [
    "B07M6PKB9W", 
    "B0952L6DRN",
    "B07HMXBSLW",
  ];
  chargingStation: AmzProduct | undefined;
  kvm: AmzProduct | undefined;
  cableTray: AmzProduct | undefined;

  constructor(public router:Router,
    private appService: AppService){}

  navigateTo(route: string){
    this.router.navigate([route]);
  }

  ngOnInit(){
    
    this.appService.getAmazonProducts(this.asins).subscribe(
      prods => {
        if(prods){
          this.chargingStation = prods.find(prod => prod.asin.toUpperCase() === this.asins[0]);
          this.kvm = prods.find(prod => prod.asin.toUpperCase() === this.asins[1]);
          this.cableTray = prods.find(prod => prod.asin.toUpperCase() === this.asins[2]);
        }
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { data } from '../../app.data';
import { AppService } from 'src/app/app.service';
import { AmzProduct } from 'server.models';

@Component({
  templateUrl: './energy-drinks.component.html'
})
export class EnergyDrinksComponent implements OnInit {
  data = data.reviewEnergyDrinksData.data;
  //celsius, super cold brew
  asins = [
    "B007R8XGJA", 
    "B08M69N14D",
  ];
  celsius: AmzProduct | undefined;
  superColdBrew: AmzProduct | undefined;

  constructor(private appService:AppService){}

  ngOnInit(){
    this.appService.getAmazonProducts(this.asins).subscribe(
      prods => {
        if(prods){
          this.celsius = prods.find(prod => prod.asin.toUpperCase() === this.asins[0]);
          this.superColdBrew = prods.find(prod => prod.asin.toUpperCase() === this.asins[1]);
        }
      }
    );
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppData } from "../app.data";
import { ArticleInterface } from "../shared/interfaces/article-interface";
import { ArticleDirective } from "./article.directive";
import { BlogService } from "../blog.service";

@Component({
  templateUrl: "./dynamic.component.html",
})
export class DynamicComponent implements OnInit {
  @ViewChild(ArticleDirective, { static: true }) articleHost!: ArticleDirective;
  data = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public blogService: BlogService
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.blogService.getBlogDataById(id)
      .subscribe({
        next: (res) => {
          if(res){
            this.loadComponent(res.data as AppData);
          }
          else{
            this.router.navigate(["/404"]);
          }
        },
      });
  }

  loadComponent(data: AppData) {
    const viewContainerRef = this.articleHost.viewContainerRef;
    const componentRef = viewContainerRef.createComponent<ArticleInterface>(
      data.component
    );
    componentRef.instance.data = data;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { ResourceUrlSanitizerPipe } from './pipes/url-sanitize-resource.pipe';
import { NumRangePipe } from './pipes/num-range.pipe';
import { ArticleCardComponent } from '../article/article-card.component';
import { ArticleDisclosureComponent } from '../article/article-disclosure.component';
import { BreadCrumbComponent } from '../article/bread-crumb.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArticleRelatedComponent } from '../article/article-related.component';
import { LoadingSpinnerComponent } from './components/spinner/spinner.component';

@NgModule({
  declarations: [
    SanitizeHtmlPipe, 
    SanitizeUrlPipe, 
    ResourceUrlSanitizerPipe, 
    NumRangePipe,
    ArticleCardComponent,
    ArticleDisclosureComponent,
    ArticleRelatedComponent,
    BreadCrumbComponent,
    ButtonComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgOptimizedImage
  ],
  exports: [
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    ResourceUrlSanitizerPipe,
    NumRangePipe,
    ArticleCardComponent,
    ArticleDisclosureComponent,
    BreadCrumbComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    ArticleRelatedComponent
  ],
  providers: [
    {
        provide: IMAGE_CONFIG,
        useValue: {
          breakpoints: [400, 640]
        }
    },
    {
        provide: IMAGE_LOADER,
        useValue: (config: ImageLoaderConfig) => {
            if(config.width){
                const src = config.src.split(".");
                return `${src[0]}-${config.width}w.${src[1]}`;
            }
            else{
                const src = config.src.split(".");
                return `${src[0]}-400w.${src[1]}`;
            }
        },
    },
]
})
export class SharedModule {}

import { Component } from '@angular/core';
import { data } from '../../app.data';

@Component({
  templateUrl: './typescript-operators.component.html'
})
export class TypeScriptOperatorsComponent {
  data = data.typeScriptOperatorsData.data;

  operatorsCheatsheet = 
`// Nullish Coalescing => ??
// right side of ?? operator returns if left side is null 
// or undefined, left side returns otherwise
let name: string | null | undefined = getName();
return name ?? "no name";

// Optional Chaining => ?.
// If shape is defined, then shape is returned. 
// Otherwise undefined is returned
type Shape = { data: { width: number, height: number }};
let shape: Shape | null | undefined = getShape();
return shape?.data.height;

// Optional Element Access => ?.
// Like Optional Chaining, returns shape or undefined.
// Allows for safe element access of array
let shapes: Shape[] | null | undefined = getShapes();
return shapes?.[0];

// Optional Chaining works with functions too
// If drawShape is null or undefined, it is ignored
// Otherwise it will be called
drawShape?.(shape);

// Double NOT operator => !!
// Is really just 2 NOT operators (! twice)
// Evaluates truthiness and ensures result is a boolean
!!5 === true;
!!"hello" === true;
!!undefined === false;
!!0 === false;

// Non-null assertion operator => !
// Type checker will assume that null and undefined
// are no longer possible values
shape!: Shape;`;
}

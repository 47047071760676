import { Component } from '@angular/core';
import { data } from 'src/app/app.data';

@Component({
  selector: 'num-range-pipe-article',
  template: `
  <dev-nav></dev-nav>
  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{data.title}}</h1>
    <p>{{data.description}}</p>
    <pre>
      <code [highlight]="numericRangePipe"></code>
    </pre>
  </div>
  `,
})
export class NumRangePipeComponent {
  data = data.numRangePipeData.data;

  numericRangePipe =
`import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numRange'
})
export class NumRangePipe implements PipeTransform {
    transform(value: number, min: number, max: number): number | undefined {
        if (Number.isNaN(value) || value === undefined || value === null) {
            return undefined;
        }

        if (value >= max) {
            return max;
        }

        if (value <= min) {
            return min;
        }

        return value;
    }
}`;
}

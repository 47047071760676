import { Route } from '@angular/router';
import { environment } from '../environments/environment';
import { Type } from '@angular/core';
import { ArticleInterface } from './shared/interfaces/article-interface';
import { ZipPipeComponent } from './dev/angular/pipes/zip-pipe.component';
import { NumMonthPipeComponent } from './dev/angular/pipes/num-month-pipe.component';
import { NumRangePipeComponent } from './dev/angular/pipes/num-range-pipe.component';
import { PhonePipeComponent } from './dev/angular/pipes/phone-pipe.component';
import { PwStrengthValidatorComponent } from './dev/angular/validators/pw-strength-validator.component';
import { AngTemplatesComponent } from './dev/angular/ang-templates/templates.component';
import { AngPipesComponent } from './dev/angular/pipes/ang-pipes.component';
import { AngValidatorsComponent } from './dev/angular/validators/ang-validators.component';
import { AngComponentsComponent } from './dev/angular/components/ang-components.component';
import { GithubEmbedComponent } from './dev/angular/components/github-embed.component';
import { AngularComponent } from './dev/angular/angular.component';
import { TypeScriptOperatorsComponent } from './dev/typescript/typescript-operators.component';
import { TypeScriptComponent } from './dev/typescript/typescript.component';
import { DevComponent } from './dev/dev.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ArticleParts } from './shared/interfaces/blog-interface';

const baseUrl = environment.url;
const imagePath = 'assets/images';
const iconPath = 'assets/icons';
const defaultImage = "jmg-512";
const defaultImageType = ".png";

export class RouteData implements Route {
    path: string;
    title: string;
    component?: Type<unknown>;
    data?: AppData;
    constructor(path: string, title: string, data: AppData, comp?: Type<unknown>) {
        this.path = path;
        this.title = title;
        this.data = data;
        if(comp){
            this.component = comp;
        }
        this.data.title = this.title;
        if(this.data.parent && this.path){
            this.data.fullPath = this.data.parent + "/" + path;
        }
        if (this.path !== baseUrl && this.path !== "") {
            const urlPath = this.data?.fullPath ? this.data.fullPath : this.path;
            this.data.url = `${baseUrl}/${urlPath}`;
        }
        else {
            this.data.url = baseUrl;
        }
        if (!this.data.image) {
            this.data.image = new ArticleImage();
        }
    }
}
export class ArticleImage {
    src: string;
    alt: string;
    constructor(name = defaultImage, fileType = defaultImageType, alt = "Jemigo logo") {
        if (name !== defaultImage) {
            this.src = `${imagePath}/${name}${fileType}`;
        }
        else {
            this.src = `${iconPath}/${name}${defaultImageType}`;
        }
        this.alt = alt;
    }
}

export interface AppData {
    parent?: string;
    fullPath?: string;
    title?: string;
    description?: string;
    image?: ArticleImage;
    articleDate?: string;
    modifiedDate?: string;
    url?: string;
    article?: ArticleParts;
    tags?: Array<string>;
    component?: Type<ArticleInterface>;
}

export const blogData: RouteData[] = [];

export const data = {
    homeData: new RouteData(
        baseUrl,
        "Jemigo",
        {
            description: "Jemigo is a WFH hub that provides the best resources, tips, guides, and WFH products to enhance your remote work lifestyle.",
            image: new ArticleImage()
        }
    ),
    notFoundData: new RouteData(
        '404',
        "404 Not Found",
        {
            description: "404 Error: Page Not Found",
            image: new ArticleImage()
        },
        NotFoundComponent
    ),
    aboutData: new RouteData(
        'about',
        "Our Story",
        {
            description: "Jemigo is a WFH hub that provides the best resources, tips, guides, and WFH products to enhance your remote work lifestyle.",
            image: new ArticleImage()
        }
    ),
    blogData: new RouteData(
        'blog',
        "Blog",
        {
            description: "Tips, guides, and how-to articles to help you make the most out of your WFH lifestyle.",
            image: new ArticleImage()
        }
    ),
    reviewsData: new RouteData(
        'reviews',
        "Reviews",
        {
            description: "Reviews for WFH related products to help remote workers save time and benefit from our experience and knowledge",
            image: new ArticleImage()
        }
    ),
    reviewEnergyDrinksData: new RouteData(
        'best-energy-drinks',
        "The Best Energy Drink",
        {
            description: "The best energy drink with amazing taste, low calorie, low carbs, and low sugar content. When working from home, drink this flavorful, health conscious energy boost.",
            parent: "reviews",
            image: new ArticleImage("energydrink", ".jpg", "An energy drink on a desk"),
            articleDate: '2022-12-4',
            modifiedDate: '2024-07-07'
        }
    ),
    cableManData: new RouteData(
        'cable-management-guide',
        'Desk Cable Management Guide for Home Offices',
        {
            description: 'WFH Cable management guide with 8 easy steps for beginners to learn how to conceal desk cords and cables in a home office.',
            image: new ArticleImage("tidy-desk", ".webp", "Organized office desk setup"),
            articleDate: '2022-01-12T12:30:00+00:00',
            modifiedDate: '2022-07-13T13:00:00+00:00'
        }
    ),
    successMindsetData: new RouteData(
        'successful-mindset',
        'Build a Successful Mindset to Achieve Your Goals',
        {
            description: 'A curated book list to help you learn how to change your mindset and become more successful.',
            image: new ArticleImage("success", ".jpg", "Man on mountain top feeling successful"),
            articleDate: '2022-01-12T12:30:00+00:00',
            modifiedDate: '2022-04-24T16:04:00+00:00',
            parent: "blog"
        }
    ),
    typeScriptData: new RouteData(
        'typescript',
        'TypeScript Development Reference',
        {
            title: 'TypeScript Development Reference',
            description: 'TypeScript references for web developers',
            image: new ArticleImage("ts-logo", ".png", "TypeScript logo")
        },
        TypeScriptComponent,
    ),
    typeScriptOperatorsData: new RouteData(
        'typescript/operators-cheatsheet',
        'TypeScript Operators Cheatsheet',
        {
            description: 'Cheat sheet for TypeScript developers to reference the most common TypeScript operators',
            image: new ArticleImage("ts-logo", ".png", "TypeScript logo"),
            articleDate: '2022-04-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        TypeScriptOperatorsComponent
    ),
    angData: new RouteData(
        'angular',
        'Angular Development Reference',
        {
            description: 'Angular references for web developers',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        AngularComponent
    ),
    zipCodePipeData: new RouteData(
        'angular/pipes/zip-code',
        'Angular Zip Code Pipe',
        {
            description: 'Angular custom zip code pipe with automatic dash formatting and only allowing numbers',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        ZipPipeComponent,
    ),
    numMonthPipeData: new RouteData(
        'angular/pipes/month-number',
        'Angular Month Name Pipe',
        {
            description: 'Angular custom pipe to display the month name based on a numeric value',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        NumMonthPipeComponent,
    ),
    numRangePipeData: new RouteData(
        'angular/pipes/numeric-range',
        'Angular Numeric Range Pipe',
        {
            description: 'Angular custom pipe to display a number between a supplied min and max range.',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-08-01T16:30:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        NumRangePipeComponent,
    ),
    phonePipeData: new RouteData(
        'angular/pipes/phone',
        'Angular Custom Phone Pipe',
        {
            description: 'Angular custom phone pipe to display an auto-formatted phone number',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        PhonePipeComponent,
    ),
    angPipesData: new RouteData(
        'angular/pipes',
        'Angular Pipes Reference',
        {
            description: 'Angular pipes quick reference guide',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        AngPipesComponent,
    ),
    angValidatorsData: new RouteData(
        'angular/validators',
        'Angular Validators Reference',
        {
            description: 'Angular validators quick reference guide with custom validators',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        AngValidatorsComponent,
    ),
    angComponentsData: new RouteData(
        'angular/components',
        'Angular Components Reference',
        {
            description: 'Angular components quick reference guide with custom components',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2023-04-24T15:36:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        AngComponentsComponent,
    ),
    angGithubGistEmbedData: new RouteData(
        'angular/components/github-gist-embed',
        'Angular Component to Embed Github Gists',
        {
            description: 'Angular component to embed github gists in Angular app',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2023-04-24T15:36:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        GithubEmbedComponent,
    ),
    angTemplatesData: new RouteData(
        'angular/templates',
        'Angular Templates Cheat Sheet',
        {
            description: 'Angular templates cheat sheet for developers',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-07-12T21:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        AngTemplatesComponent,
    ),
    angPwStrengthValidatorData: new RouteData(
        'angular/validators/password-strength-validator',
        'Angular Simple Password Strength Validator',
        {
            description: 'Angular custom password strength validator',
            image: new ArticleImage("angular", ".png", "Angular logo"),
            articleDate: '2022-03-07T17:24:00+00:00',
            modifiedDate: '2023-04-24T15:36:00+00:00'
        },
        PwStrengthValidatorComponent,
    ),
    wfhPart1Data: new RouteData(
        'wfh-guide/reasons-to-work-from-home',
        'Work from Home Guide: Reasons to Work from Home',
        {
            description: 'Jemigo WFH Guide Part 1 describes the real motives behind the work from home movement and why WFH leads to greater happiness.',
            image: new ArticleImage("wfh", ".webp", "Person working outside with scenic landscape"),
            articleDate: '2022-06-15T12:40:00+00:00',
            modifiedDate: '2022-06-16T20:00:00+00:00'
        }
    ),
    wfhPart2Data: new RouteData(
        'wfh-guide/take-control',
        'Work from Home Guide: Take Control by Working from Home',
        {
            description: 'Working from home helps employees take control of their environment to gain a better balance, save money, and relieve stress.',
            image: new ArticleImage("wfh", ".webp", "Person working outside with scenic landscape"),
            articleDate: '2022-06-15T12:40:00+00:00',
            modifiedDate: '2022-10-11T17:00:00+00:00'
        }
    ),
    wfhGuideData: new RouteData(
        'wfh-guide',
        'Work from Home Guide',
        {
            description: 'The Jemigo WFH Guide helps remote workers and employers gain a greater understanding for the WFH landscape and how much it can positively impact the workforce.',
            image: new ArticleImage("wfh", ".webp", "Person working outside with scenic landscape"),
            articleDate: '2022-06-15',
            modifiedDate: '2022-11-11'
        }
    ),
    wfhOfficeData: new RouteData(
        'wfh-office',
        'WFH Office Essentials',
        {
            description: 'Best WFH office products that every remote worker needs for comfort and productivity.',
            image: new ArticleImage("wfh", ".webp", "Person working outside with scenic landscape"),
            articleDate: '2022-10-13',
            modifiedDate: '2022-10-13'
        }
    ),
    wfhTopTenEssentialsData: new RouteData(
        'wfh-office/top-10-wfh-essentials',
        'Top 10 WFH Essentials',
        {
            description: 'The top ten WFH products that are essential for new and veteran remote workers.',
            image: new ArticleImage("wfh", ".webp", "Person working outside with scenic landscape"),
            articleDate: '2022-10-22',
            modifiedDate: '2022-10-22',
        }
    ),
    toolsData: new RouteData(
        'wfh-tools',
        'WFH Tools',
        {
            description: 'Helpful and free online WFH tools',
            articleDate: '2022-06-15',
            modifiedDate: '2022-07-24',
            image: new ArticleImage()
        }
    ),
    wfhTimeSavingsToolData: new RouteData(
        'wfh-tools/wfh-time-savings-calculator',
        'WFH Time Savings Calculator',
        {
            description: 'Calculate how much time you can save by working from home.',
            articleDate: '2022-07-24',
            modifiedDate: '2022-07-24',
            image: new ArticleImage()
        }
    ),
    percentChangeToolData: new RouteData(
        'wfh-tools/percent-change-calculator',
        'Percent Change Calculator',
        {
            description: 'Calculate the percentage of change between two values.',
            articleDate: '2022-07-24',
            modifiedDate: '2022-07-24',
            image: new ArticleImage()
        }
    ),
    devData: new RouteData(
        'dev',
        'Web Development References and Cheat Sheets',
        {
            description: 'Web development referencesa and cheat sheets for easy syntax lookup',
            articleDate: '2022-06-15',
            modifiedDate: '2023-04-24',
            image: new ArticleImage()
        },
        DevComponent,
    ),
};

<dev-nav></dev-nav>
<main class="pt-20" style="max-width:1400px;">
    <header>
        <h1 class="mt-0 center" style="padding-top:10px;">{{data.title}}</h1>
    </header>
    <section style="padding:10px;">
        <div class="flex-wrapper" style="margin-bottom:10px;">

        </div>
    </section>
</main>
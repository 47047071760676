import { Component } from '@angular/core';
import { data } from '../app.data';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent{
  title = data.aboutData.title;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate{

    constructor(public router: Router, private http: HttpClient) { }

    canActivate() {
        if(typeof window !== "undefined"){
            const token = window?.prompt("type: ");
            return this.http.post(`${environment.url}/api/access`, {token: token}, {observe: 'response', responseType: 'text'}).pipe(
                map((res) => { return res.status === 200 }),
                catchError(() => of(this.router.parseUrl('/'))),
            );
        }
        else{
            return false;
        }
    }
}
export const TREE_FACTS = [
    "In Los Angeles, trees remove nearly 2,000 tons of air pollution each year.",
    "Global forests removed about one-third of fossil fuel emissions annually from 1990 to 2007.",
    "Every 1.2 seconds, humans destroy an area of forest the size of a football field.",
    "46% of the world's forests are already destroyed.",
    "80,000 acres of forests disappear from the Earth every day.",
    "3.5 out of 10 trees get chopped down and processed into pulp for making paper products.",
    "15% of all greenhouse gas emissions are the result of deforestation",
    "28,000 species are expected to become extinct by the next 25 years due to deforestation",
    "The world's rainforests could completely vanish in 100 years at the current rate of deforestation.",
    "Roadside trees reduce nearby indoor air pollution by more than 50%",
    "Office workers with a view of trees report significantly less stress and more satisfaction.",
    "A study of 10 cities found community forests save an average of one life each year.",
    "One large tree can provide a day’s supply of oxygen for up to four people.",
    "More than 20% of the world’s oxygen is produced in the Amazon Rainforest",
    "Forested watersheds provide quality drinking water to more than 180 million Americans.",
    "Trees cool the city by up to 10°F by shading our homes and streets and releasing water vapor into the air through their leaves.",
    "Trees absorb carbon dioxide (CO2), removing and storing the carbon while releasing the oxygen back into the air.",
    "In one year, an acre of mature trees absorbs the amount of CO2 produced by a car driven 26,000 miles.",
    "During one year, a mature tree will absorb more than 48 pounds of carbon dioxide from the atmosphere and release oxygen in exchange.",
    "Trees properly placed around buildings can reduce air conditioning needs by 30% and can save 20–50% in energy used for heating.",
    "In Baltimore, a 10% increase in tree canopy corresponded to a 12% decrease in crime.",
    "Chicago public housing residents with nearby trees and natural landscapes reported 25% fewer acts of domestic aggression and violence.",
    "In New York City, it has been calculated that community trees provide $5.60 in benefits for every dollar spent on tree planting and care.",
    "In Portland, Oregon, homes with street trees sold for $7,130 more, on average, and 1.7 days more quickly.",
    "In Fulton County (Atlanta), Georgia, mature trees positively influenced home sale prices. Homes sold for nearly $105,000 more in neighborhoods with mature trees.",
    "Trees are the longest living organisms on Earth, and never die of old age.",
    "Trees release chemicals called phytoncides. When we breathe them in, it can reduce blood pressure, lower anxiety levels, and increase pain threshold.",
    "Trees prevent soil from eroding into our waterways, reduce stormwater runoff, and lessen flood damage.",
    "Some trees have been to the moon. Moon trees were grown from seeds taken to the moon during the Apollo 14 mission in early 1971.",
  ];
import { Component } from '@angular/core';
import { data } from 'src/app/app.data';

@Component({
  selector: 'num-month-pipe-article',
  template: `
  <dev-nav></dev-nav>
  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{title}}</h1>
    <p>A pipe that converts a numeric month value to a month name in the English Gregorian calendar.</p>
    <pre>
      <code [highlight]="monthNamePipe"></code>
    </pre>
  </div>
  `,
})
export class NumMonthPipeComponent {
  title = data.numMonthPipeData.title;

  monthNamePipe =
`import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {
  transform(value: number): string {
      switch(value){
        case 1: 
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "August";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";
        default:
            return "";
      }
  }
}`;
}

import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";

import {
  BrowserModule,
  //provideClientHydration,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes, ExtraOptions } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TransferHttpCacheModule } from "@nguniversal/common";

import { AppService } from "./app.service";
import { AppComponent } from "./app.component";
import { LayoutModule } from "@angular/cdk/layout";
import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
// import { ServiceWorkerModule } from "@angular/service-worker";
// import { environment } from "../environments/environment";
import { AboutComponent } from "./about/about.component";

import { ArticleDirective } from "./dynamic/article.directive";

import { SharedModule } from "./shared/shared.module";
import { ReviewsComponent } from "./reviews/reviews.component";
import { EnergyDrinksComponent } from "./reviews/energy-drinks/energy-drinks.component";
import { SuccessfulMindsetComponent } from "./blog/successful-mindset/successful-mindset.component";
import { data } from "./app.data";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ArticlesModule } from "./shared/articles.module";
import { DynamicComponent } from "./dynamic/dynamic.component";
import { DynamicArticleComponent } from "./dynamic/dynamic-templates/dynamic-article.component";
import { AmzInterceptor } from "./shared/interceptors/amz-interceptor";
import { dynamicTemplates } from "./dynamic/dynamic-templates/dynamic.templates";
import { AuthGuard } from "./auth-guard.service";
import { BlogService } from "./blog.service";
import { HIGHLIGHT_OPTIONS, HighlightModule } from "ngx-highlightjs";

const routes: Routes = [
  {
    path: "about",
    title: data.aboutData.title,
    component: AboutComponent,
    data: data.aboutData.data,
  },

  //Tools
  {
    path: "wfh-tools",
    title: data.toolsData.title,
    data: data.toolsData.data,
    loadChildren: () =>
      import("./tools/tools.module").then((m) => m.ToolsModule),
  },

  //Reviews
  {
    path: "reviews",
    title: data.reviewsData.title,
    component: ReviewsComponent,
    data: data.reviewsData.data,
  },
  {
    path: "reviews/best-energy-drinks",
    title: data.reviewEnergyDrinksData.title,
    component: EnergyDrinksComponent,
    data: data.reviewEnergyDrinksData.data,
  },

  //Blog
  {
    path: "blog",
    title: data.blogData.title,
    data: data.blogData.data,
    loadChildren: () => import("./blog/blog.module").then((m) => m.BlogModule),
  },
  // old blog path, remove at some point
  { path: "tips-and-guides", redirectTo: "blog", pathMatch: "full" },
  {
    path: "cable-management-guide",
    title: data.cableManData.title,
    data: data.cableManData.data,
    loadChildren: () =>
      import("./blog/diy-cable-management/diy-cable-management.module").then(
        (m) => m.DiyCableManagementModule),
  },

  //WFH Guide
  {
    path: "wfh-guide",
    title: data.wfhGuideData.title,
    data: data.wfhGuideData.data,
    loadChildren: () =>
      import("./blog/wfh-series/wfh.module").then((m) => m.WfhModule),
  },

  //Dev
  {
    path: "dev",
    title: data.devData.title,
    data: data.devData.data,
    loadChildren: () => import("./dev/dev.module").then((m) => m.DevModule),
  },

  {
    path: "admin",
    title: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },

  //App paths
  {
    path: "404",
    component: NotFoundComponent,
    title: "404 Not Found",
  },
  {
    path: "",
    component: HomeComponent,
    title: data.homeData.title,
    data: data.homeData.data,
  },
  { path: "**", redirectTo: "404", data: data.notFoundData.data },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: "enabled",
  onSameUrlNavigation: "reload",
  initialNavigation: "enabledBlocking",
  scrollPositionRestoration: "enabled",
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    EnergyDrinksComponent,
    ReviewsComponent,
    SuccessfulMindsetComponent,
    NotFoundComponent,
    DynamicComponent,
    DynamicArticleComponent,
    ArticleDirective,
    ...dynamicTemplates,
  ],
  imports: [
    // OLD Version
    //BrowserModule.withServerTransition({ appId: 'ec3c8b91-5fe3-4184-81cc-975768d33d1b' }),
    // NEW Way
    // { provide: APP_ID, useValue: 'ec3c8b91-5fe3-4184-81cc-975768d33d1b' },
    BrowserModule,
    TransferHttpCacheModule,
    CommonModule,
    ArticlesModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, routerOptions),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production,
    // }),
    FontAwesomeModule,
    HighlightModule,
    NgOptimizedImage
  ],
  providers: [
    //provideClientHydration(),
    AppService,
    BlogService,
    HttpClient,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 4000, horizontalPosition: "center" },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AmzInterceptor, multi: true },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          typescript: () => import("highlight.js/lib/languages/typescript"),
          css: () => import("highlight.js/lib/languages/css"),
          xml: () => import("highlight.js/lib/languages/xml"),
        },
      },
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [400, 640]
      }
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.width) {
          const src = config.src.split(".");
          return `${src[0]}-${config.width}w.${src[1]}`;
        }
        else {
          const src = config.src.split(".");
          return `${src[0]}-400w.${src[1]}`;
        }
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

<dev-nav></dev-nav>

<main style="padding: 20px">
  <h1 class="mt-0">{{data.title}}</h1>
  
  <section>
    <p>
      <a routerLink="operators-cheatsheet">TypeScript Operators Cheatsheet</a>
    </p>
  </section>
</main>